/*********************************************
:: About Page
********************************************/

.page-about {

  .title-module {
    width: 1150px;
    margin: 0 auto;
    display: block;

    h1 {
      font-size: 24px;
      line-height: 42px;
      padding: 48px 0 0 0px;
      font-weight: 600;

      @include mappy-bp(max-width mediumish){
        font-size: 20px;
        padding: 25px 0 40px 0;
        font-weight: 600;
        color: #231f20;
      }
    }

    h1:after {
      content: '';
      height: 5px;
      width: 40px;
      background: #e1a625;
      display: block;
      margin: 6px auto;

      @include mappy-bp(max-width mediumish){
        margin: -2px auto;
      }
    }

    @include mappy-bp(max-width 1150px){
      width: auto;
    }
  }

  .title-bg {
    background-image: url("/images/desktop-title-banner.jpg");
    background-repeat: no-repeat;
    height: 150px;
    margin-top: 69px;
    width: 100%;

    @include mappy-bp(max-width mediumish){
      width: auto;
      height: 100px;
      margin-top: 5px;
    }
  }

  .content-wrapper {
    width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include mappy-bp(max-width 1150px){
      width: auto;
    }

    @include mappy-bp(max-width mediumish){
      flex-direction: column;

    }
  }

  .image-right {
    flex-basis: 375px;

    .col1 {
      display: flex;
      flex-direction: column;
      padding-left:88px;
      align-items: center;

      @include mappy-bp(max-width mediumish){
        padding-left:0;

      }

      .image-container{
        @include mappy-bp(max-width mediumish){
          width:355px;
          height: auto;
          padding: 0 2%;
        }
      }

      img.image-about {
        margin-top: -63px;
        border: 5px solid #fff;
      }

      img.image-about2 {
        display: none;
        margin-top: -63px;
        border: 5px solid #fff;

        @include mappy-bp(max-width mediumish){
          display:block;
          width: 100%;
          height:auto;
          
        }
      }

      .rectangle {
        background:url(/images/rectangle.png) top right no-repeat;
        margin-right:5px;
        height:250px;
        margin-top:50px;
        width: 282px;

        @include mappy-bp(max-width mediumish){
          display: none;

        }
      }
    }
    @include mappy-bp(max-width mediumish){
      flex-basis: 0px;

    }

  }

  .content-left {
    flex-basis: auto;
    float: left;

    @include mappy-bp(mediumish) {
      flex-basis: 730px;
    }

    .wysiwyg{
      padding: 0px 0px 0px 40px;
      width: 80%;

      @include mappy-bp(max-width mediumish){
        padding: 0px 0px 0px 0px;
        width: auto;
        margin-top: 25px;
      }

      h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        color: #231f20;
      }

      ul li {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #696865;
        margin-left: 15px;

        @include mappy-bp(max-width mediumish){
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #666666;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #696865;

        @include mappy-bp(max-width mediumish){
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #666666;
        }
      }
    }

    @include mappy-bp(max-width mediumish){
      padding: 0px 5px 0px 15px;
      width: 95%;
      float: none;

    }
  }

}