/*********************************************
:: Pickadate.js Plugin Styles Override                    
********************************************/

.calendar-fields-container{
    width: 100%;
    max-width:500px;
    position: relative;
    >button{
		position: absolute;
		top: 14px;
		right: 15px;
		font-size: 1.3em;
		line-height: 1.3;
		color: $color-brand-1;
    }
}

// ############################################# 

.picker {
    max-width: 320px;
	
    td, th{
        padding: 0px;
        text-align: inherit;
        border: none;
    }
    
    display: none;
}

.picker__holder {
  overflow-y: initial;
}

.picker--opened{
	display: block !important;
}

.picker__footer {
  display: none;
}

.picker select{
    display: inline-block;
    height: auto;
    width: auto;
	font-size: 16px;
	line-height: 1.2;
    padding: 0px;
    border: none !important;
    margin: 0px !important;
}