/*********************************************
:: Layout
********************************************/

//
.titanic {
    float: none;
}

// Remove blue highlight on click
* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/*******************************************/
// Main <section class="main" role="main">
.main {
    display: block;
    min-height: calc(100vh - #{$header-height-mobile + $footer-height-mobile});

    @include mappy-bp(mediumish){
        min-height: calc(100vh - #{$header-height-desktop + $footer-height-desktop});
    }
}

/*******************************************/
// Main <aside class="sidebar" role="complementary"> element
.sidebar {
    float: left;
    width: 100%;
    padding: 20px 15px 20px 15px;

    @include mappy-bp(mediumish){
        padding: 45px 0px 45px 0px;
    }
}

/*******************************************/
// Clear
.clear-after {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Content Max Width
.content-max-width {
    position: relative;
    width: 100%;
    padding: 0 6%;
    clear: both;

    @include mappy-bp(mediumish){
        max-width: 940px;
        margin: 0 auto;
        padding: 0px;
    }
}

/*******************************************/
// Content Slider Width
.content-slider-width {
    padding: 0 0%;
    position: relative;
    clear: both;
    @include mappy-bp(mediumish){
        max-width: 1150px;
        margin: 0 auto;
        padding: 0px;
    }
    @include mappy-bp(large){
        max-width: 1150px;
    }
}

/*******************************************/
// Narrow Content Max Width
.content-narrow-width {
    @include mappy-bp(mediumish){
        max-width: 740px;
        margin: 0 auto;
        clear: both;
    }
    @include mappy-bp(large){
        max-width: 740px;
    }
}

/*******************************************/
// Breakpoints
.mob, .mobile {
    @include mappy-bp(mediumish){
        display: none !important;
    }
}

.desk, .desktop {
    @include mappy-bp(max-width mediumish){
        display: none !important;
    }
}

/*******************************************/
.border-footer{
    margin-top: 100px;
    width: 100%;
    border-top: 5px solid #f0efec;

    @include mappy-bp(max-width small) {
        margin-top: 60px;
    }
}