/*********************************************
:: special Projects Page
********************************************/

.page-special-projects {
  margin-top: 25px;

  .border-footer {
    margin-top: 50px !important;
  }

  .title-module {
    width: 1150px;
    margin: 0 auto;
    display: block;
    padding-bottom: 0;

    @include mappy-bp(mediumish) {
      padding-bottom: 55px;
    }

    h1 {
      font-size: 24px;
      line-height: 42px;
      padding: 48px 0 0 0px;
      font-weight: 600;

      @include mappy-bp(max-width mediumish){
        font-size: 20px;
        padding: 25px 0 40px 0;
        font-weight: 600;
        color: #231f20;
      }
    }

    h1:after {
      content: '';
      height: 5px;
      width: 40px;
      background: #e1a625;
      display: block;
      margin: 6px auto;

      @include mappy-bp(max-width mediumish){
        margin: -2px auto;
      }
    }

    @include mappy-bp(max-width 1150px){
      width: auto;
    }
  }

  .title-bg {
    background-image: url("/images/desktop-title-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
    margin-top: 69px;
    width: 100%;

    @include mappy-bp(max-width mediumish){
      width: auto;
      height: 100px;
      margin-top: 5px;
    }
  }

  .content-wrapper {
    width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include mappy-bp(max-width 1150px){
      width: auto;
    }

    @include mappy-bp(max-width mediumish){
      flex-direction: row;

    }
  }

  .body-content {
    flex-basis: auto;
    float: left;

    @include mappy-bp(mediumish) {
      flex-basis: 815px;
    }

    .wysiwyg{
      padding: 0px 0px 0px 40px;
      //width: 80%;

      @include mappy-bp(max-width mediumish){
        padding: 0px 0px 0px 0px;
        width: auto;
        margin-top: 25px;
      }

      h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        color: #231f20;
      }

      ul li {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #696865;
        margin-left: 15px;

        @include mappy-bp(max-width mediumish){
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #666666;
        }
      }

      p {
        font-size: 16px;
        color: #58595B;
        //font-family: Gibson;
        font-weight: 300;
        letter-spacing: -0.2px;
        line-height: 24px;

        @include mappy-bp(max-width mediumish){
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #666666;
        }
      }
    }

    @include mappy-bp(max-width mediumish){
      padding: 0px 5px 0px 15px;
      width: 95%;
      float: none;

    }

    .lead-text {
      margin-top: 10px;
      padding: 0px 0px 0px 40px;
      @include mappy-bp(mediumish) {
        margin-top: 40px;
      }

      h2 {
        color: #231F20;
        font-family: Geogrotesque !important;
        font-size: 32px;
        font-weight: 600;
        letter-spacing: 3px;
        line-height: 40px;
      }
    }
  }

  .projects-container {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
    flex-wrap: wrap;
    column-count: 1;

    @include mappy-bp(mediumish) {
      margin-top: 40px;
    }

    @include mappy-bp(mediumish) {
      justify-content: space-between;
      flex-direction: row;
      padding: 0 10px;
      column-count: 2;
    }


    article {
      width: 100%;
      margin-bottom: 30px;

      @include mappy-bp(mediumish) {
        width: calc(50% - 30px);
      }

      .article-image {
        img {
          width: 100%;
          border-bottom: 5px solid #E1A625;
          background-color: #E1A625;

        }
      }

      .article-title {
        margin: 20px 0;

        color: #231F20;
        font-family: "myriad-pro";
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
      }

      .article-content {
          p {
            color: #696865;
            font-family: "myriad-pro";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;

          }

      }


    }
  }

}