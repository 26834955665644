/*********************************************
:: Testimonials
********************************************/
.page-testimonials {

    .title-module {
        width: 1150px;
        margin: 0 auto;
        display: block;

        h1 {
            font-size: 24px;
            line-height: 42px;
            padding: 48px 0 0 0px;
            font-weight: 600;

            @include mappy-bp(max-width mediumish){
                font-size: 20px;
                padding: 25px 0 0 0px;
            }
        }

        h1:after {
            content: '';
            height: 5px;
            width: 40px;
            background: #e1a625;
            display: block;
            margin: 6px auto;

            @include mappy-bp(max-width mediumish){
                margin: -2px auto;
              }
        }

        @include mappy-bp(max-width 1150px){
            width: auto;
            padding-bottom: 25px;
        }
    }

    .title-bg {
        background-image: url("/images/desktop-title-banner.jpg");
        background-repeat: no-repeat;
        height: 150px;
        margin-top: 69px;
        width: 100%;

        @include mappy-bp(max-width mediumish){
            width: auto;
            height: 100px;
            margin-top: 5px;
          }
    }

    .detail-container {
        display: inline-block;
        width: 100%;


        .content-max-width {
            padding-left: 90px;
            padding-right: 85px;

            @include mappy-bp(max-width small){
                padding-left: 35px;
                padding-right: 10px;
            }
        }

        article {
            display: none;
            float: left;
            width: 100%;
            padding-bottom: 40px;
            clear: both;

            @include mappy-bp(max-width mediumish){
                padding-bottom: 0px;
            }

            .author {
                margin: 20px 0 0 0;
                padding: 20px 0 30px 0;


                @include mappy-bp(max-width mediumish){
                    float: left;
                    margin: 10px 0px 0 0;
                    width: 150px;
                    padding: 0 0 0 0;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 16px;
                    color: #666666;
                    line-height: 24px;
                    font-weight: 600;
                }

                span {
                    display: block;
                    font-size: 16px;
                    font-style: italic;
                    line-height: 24px;
                    color: #b35022;
                    margin-bottom: 13px;
                }

                img {
                    margin-top: 30px;
                    max-width: 220px;
                }
            }

            .border {
                width: 30px;
                height: 3px;
                margin-left: 0%;
                border-bottom: 3px solid #deddda;
                margin-bottom: 40px;
                border-radius: 50px;
            }

            .quote {
                position: relative;
                padding: 0;

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #666666;

                    @include mappy-bp(max-width small){
                        font-size: 14px;
                        line-height: 21px;
                    }
                }

                @include mappy-bp(mediumish){
                    float: right;
                    margin-top: 28px;
                    padding-top: 2px;
                    padding-left: 27px;
                    width: calc(100% - 194px);
                }

                &:before{
                    @include mappy-bp(max-width mediumish){
                        content: url(/images/quote.png);
                        position: absolute;
                        top: -12px;
                        left: -30px;

                        font-size: $font-family-1;
                        font-size: 28px;
                        font-style: italic;
                        color: #333;

                        letter-spacing: 0.5px;
                    }

                    @include mappy-bp(mediumish){
                        content: url(/images/quote.png);
                        position: absolute;
                        top: -12px;
                        left: 0px;

                        font-size: $font-family-1;
                        font-size: 28px;
                        font-style: italic;
                        color: #333;

                        letter-spacing: 0.5px;
                    }
                }
            }
        }

        article.special {
            display: none;

            @include mappy-bp(mediumish){
                display: inline-block;
                width: 100%;
                padding: 10px 12.5% 50px 12.5%;
            }

            .author {
                display: none;
            }

            .quote {
                float: left;
                position: relative;
                padding: 0 50px 0 0; 
                width: 100%;

                border-right: 3px solid $color-brand-1;

                &:before{
                    content: url(/images/quote.png);
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #666666;
                    text-align: right;
                    margin-bottom: 0px;

                    @include mappy-bp(smallish){
                        font-size: 14px;
                        line-height: 21px;
                        color: #666666;
                    }
                }

                a {
                    display: none;
                }
            }
        }

        article.show {
            display: inline-block;
            animation: fade-in .5s;

            @include mappy-bp(max-width smallish){
                padding-left: 15px;
            }
        }

        article:last-child {
            padding-bottom: 100px;
        }

        .button-more {
            display: none;
        }

    }//DETAIL-CONTAINER

}