/*********************************************
:: Contact Page                   
********************************************/
.page-contact {

    .title-module {
        width: 1150px;
        margin: 0 auto;
        display: block;
        padding-bottom: 0px;
        h1 {
            font-size: 24px;
            line-height: 42px;
            padding: 48px 0 0 0px;
            font-weight: 600;

            @include mappy-bp(max-width mediumish){
                font-size: 20px;
                padding: 26px 0 40px 0;
                font-weight: 600;
                color: #231f20;
              }
        }

        h1:after {
            content: '';
            height: 5px;
            width: 40px;
            background: #e1a625;
            display: block;
            margin: 6px auto;

            @include mappy-bp(max-width mediumish){
                margin: -2px auto;
              }
        }

        @include mappy-bp(max-width 1150px){
            width: auto;
            padding-bottom: 25px;
        }
    }

    .title-bg {
        background-image: url("/images/desktop-title-banner.jpg");
        background-repeat: no-repeat;
        height: 150px;
        margin-top: 69px;
        width: 100%;

        @include mappy-bp(max-width mediumish){
            width: auto;
            height: 100px;
            margin-top: 0px;
          }
    }

    .contact-container {

        .content-max-width {
            max-width:1140px;
            padding:0 4%;
            margin-top: 16px;

            @include mappy-bp(mediumish) {
                
                padding:0 30px;
            }
        }

        h3 {
            padding: 0 0 6px 0;
            width: 100%;

            font-family: $font-family-1;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            color: black;

            border-bottom: 1px solid #CCC;
        }

        .col1 {
            float: left;
            margin-bottom: 28px;
            width: 100%;
            padding-top: 11px;

            @include mappy-bp(mediumish) {
                width: 50%;
                max-width: 420px;
                padding-top: 5px;
                margin-bottom: 35px;
            }

            h3 {
                max-width: 323px;
                padding: 30px 0 4px 0;
            }

            .intro {
                display: none;

                @include mappy-bp(mediumish) {
                    display: inline-block;
                    margin: 0px 0 30px 0;
                    font-size: 16px;
                }
            }

            p {
                margin: 0px 0 4px 0;
                font-size: 14px;
                line-height: 22px;
                font-weight: 400;
                color: #666666;

                @include mappy-bp(mediumish) {
                    margin: 0px 0 8px 0;
                    font-size: 15px;
                    line-height: 24px;
                }
            }

            a {
                display: block;
            }

            .phone {
                margin: 0px;
                font-size: 24px;
                font-weight: 400;
                color: #696865;
                margin-bottom: 12px;

                @include mappy-bp(mediumish) {
                    font-size: 30px;
                }
            }

            .email {
                font-size: 17px;
                color: #b9881f;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 17px;

                @include mappy-bp(mediumish) {
                    margin-bottom: 33px;
                }
            }

        }

        .col2 {
            float: left;
            padding: 20px 0% 0 0%;
            width: 100%;

            @include mappy-bp(mediumish) {
                width: 51.8%;
                float: right;
                padding: 0px;
            }
        }

        .map-area {
            display: inline-block;
            margin: 30px 0;
            width: 100%;

            @include mappy-bp(mediumish) {
                margin: 30px 0;
            }
            .map {
                float: left;
                margin: auto;
                width: 100%;
                height: 206px;

                @include mappy-bp(mediumish) {
                    height: 300px;
                }
            }
        }

        input {
            display: inline-block;
            width: 97%;
            height: 40px;
            position: relative;
            left: 0;
            outline: none;
            border: 1px solid #D8D8D8;
            background-color: white;
            border-radius: 1px;
            margin-top: 5px;
        }

        .form-field{
            padding: 0 0 5px;

            @include mappy-bp(mediumish) {
                padding: 0 0 15px;
            }
        }

        textarea {
            display: inline-block;
            width: 97%;
            height: 112px;
            position: relative;
            left: 0;
            outline: none;
            border: 1px solid #D8D8D8;
            background-color: white;
            border-radius: 1px;
            margin-top: 5px;

            @include mappy-bp(mediumish) {
                width: 99%;
            }
        }

        *::placeholder {
            font-family: $font-family-1;
            color: #666666;
            font-size:14px;
            font-weight: 400;
            
        }

        .myButton {
            cursor:pointer;
            border:none;
            width:170px;
            height:50px;
        }

        .sendbutton {
            cursor:pointer;
            border:none;
            font-size: 18px;
            font-weight: 700;
            color: #fff;
            line-height: 20px;
            padding: 14px 18px;
            background-color: #da6129;
            border-bottom: 3px solid #b35022;
            height: 50px;
            width: 172px;
            margin: 10px auto -30px;
            border-radius: 1px;

            @include mappy-bp(mediumish) {
                margin: 0 5px 20px auto;
            }
          }

        

    }//CONTACT-CONTAINER
    .contact-border {
        margin-top: -30px;
        @include mappy-bp(mediumish){
            margin-top: 0px;
        }
        
    }
}