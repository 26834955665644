/*********************************************
  :: Main Mob Menu                             
********************************************/
body.open-menu {
    
    #mobile-menu {
        right: 0;
        overflow: visible;
        .menu-close-icon {
            left: -52px;
        }
    }
}

#mobile-menu {
    
    position: fixed;
    top: 0;
    bottom: 0; 
    right: -323px;

    width: calc(100% - 52px);
    max-width: 323px;

    background: #f0efec;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 999;
    transition: all .25s;
    -webkit-backface-visibility: hidden;
    border-left: 5px solid #e1a625;
    overflow-y: auto;

    @include mappy-bp(mediumish){
        display:none;
    }

    .menu-close-icon {
        position: absolute;
        top: 0; left: 0;

        width: 52px;
        height: 56px;

        border: none;
        outline: none;

        span {
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            color: white;
            position: absolute;
        }
    }

    .menu-content {
        overflow-y: auto;
        &::-webkit-scrollbar { 
            display: none; 
        }
    }

    a {
        font-family: $font-family-1;
        font-size: 18px;
        font-weight: 400;
        color: #231f20;
        line-height: 20px;

        &.selected {
            color: #b9881f;
            line-height: 20px;
            font-weight: 700;
            font-size: 18px;
            &:hover { opacity: 1; }
            &:active { opacity: 1; }
        }

        
    }
    .ongoing-margin{
        margin-top: 20px;
    }

    .ongoing-text {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		color: #696865;

		&.selected {
			color: #696865;
		}
	}

	.contact-num {
		font-size: 24px;
		font-weight: 400;
		color: #Da6129;
    }

    a.sub-menu{
        color: #696865;
        font-size: 16px;
        font-weight: 400;
        line-height:18px;
    }

    ul {
        padding: 30px 15px 0 20px;
    }

    li {
        margin: 0;
        padding: 10px 0;

        ul {
            margin-top: 10px;
            border-left: 5px solid #deddda;
            padding: 0 0 0 15px;

            
        }
        
        li:last-child {
            border-bottom: none;
        }
    }
}