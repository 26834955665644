/*********************************************
:: Home Page                   
********************************************/
.page-home {
  width: 100%;
  margin: 0 auto;

    .top-gradient-image {
      width: 100%;
      margin-top: -38px;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
        position: absolute;
        z-index: 9;
      }
    }
    .hero-container {
        position: relative;
    	  width: 100%;

      .slide-info {
        position: absolute;
        max-width: 1150px;
        width: 1150px;
        top: 375px;
        left: 50%;
        transform: translateX(-50%);
      }


      .next {
        right: 0;
        //top: 402px;
        position: absolute;

        @include mappy-bp(max-width mediumish){
          //right: 75px;
          //top: 252px;
          position: absolute;
        }

        @include mappy-bp(max-width large){
          right: 150px;
          top: 252px;
          position: absolute;
        }

        .slider-btn2{
          display: none;
        }

        @include mappy-bp(max-width medium){
          top: 100px;
          right: 0px;

          .slider-btn1{
            display: none;
          }

          .slider-btn2{
            display: block;
          }
        }
      }

      .prev {
        //left: 9%;
        //top: 402px;
        position: absolute;

        @include mappy-bp(max-width mediumish){
          //left: 10px;
          //top: 252px;
          position: absolute;
        }

        @include mappy-bp(max-width large){
          //right: 75px;
          //top: 252px;
          position: absolute;
        }

        .slider-btn2{
          display: none;
        }

        @include mappy-bp(max-width medium){
          top: 100px;
          left: 0px;

          .slider-btn1{
            display: none;
          }

          .slider-btn2{
            display: block;
          }
        }
      }
        .slide {
            position: relative;
            width: 100%;
            max-height: 604px;
            min-height: 350px;

            @include bg(cover);

            @include mappy-bp(mediumish){
                min-height: 613px; 
            }

            @include mappy-bp(max-width medium){
              min-height: 240px; 
          }

        }

    	.slide-text {
    		position: absolute;
            //top: 388px;
            left: 6%;
            background: rgba(25, 25, 25, .4);
            color: white;
            width:650px;
            min-height: 112px;
            padding: 2px 10px 15px 20px;

    		@include mappy-bp(mediumish){
                //top: 375px;
                //left: 15%;
                //left :20%;
    		}

        @include mappy-bp(max-width mediumish){
          top: 241px;
          background: rgba(25, 25, 25, .4);
          color: white;
          width: 450px;
          min-height: 60px;
        }

        @include mappy-bp(max-width medium){
          padding: 3px 0px 0px 10px;
          width: 303px;
          top: 140px;
        }

    		h1, h2 {
    			font-size: 44px;
    			font-weight: 600;
    			color: #f0efec;

    			@include mappy-bp(mediumish){
                  font-size: 44px;
                    line-height: 1.3;
    			}

          @include mappy-bp(max-width mediumish){
              font-size: 33px;
          }

          @include mappy-bp(max-width medium){
            font-size: 21px;
            line-height: 30px;
          }
    		}
    	}
    }//HERO-CONTAINER

    .service {
      max-width: 1150px;
      margin: 77px auto 0px auto;
      display: flex;
      flex-direction: column;

      @include mappy-bp (max-width small){
        margin-top: 40px;
      }

      .content-max-width {
        max-width: 100%;
        text-align: center;
        margin-bottom: 11px;
      }

      h1 {
        font-size: 18px;
        font-weight: 600;
        color: #333333;

        @include mappy-bp(mediumish) {
          font-size: 24px;
        }
      }

      .service-main-title {
        border-bottom: 1px solid #dcdcdc; 
        line-height:0.1em; 
        margin:10px 0 20px;
      }

      .service-title {
        background:#fff; 
        padding:0 20px; 
      }

      .services-wrapper {
        flex-direction: column;
        justify-content: space-evenly;
        display: flex;
        padding: 0 4%;
        align-items: center;

        // @include mappy-bp(max-width 1025){
        //   justify-content: space-evenly;
        // }

        @include mappy-bp(medium){
          flex-direction: row;
          justify-content: space-between;
          flex-wrap:wrap;
        }
      }

      .service-container {
        flex-basis: auto;
        margin-top:20px;

        @include mappy-bp(max-width medium){
          width: 345px;
          margin-top: 0px;
          margin-bottom: 16px;
        }
      }

      .service-image {
        border-bottom: 5px solid #deddda;
        width: 100%;
        
        @include mappy-bp(max-width mediumish){
          display: none;
        }
      }

      .service-image2 {
        display: none;
        border-bottom: 5px solid #deddda;

        @include mappy-bp(max-width mediumish){
          display:block;
          background: #eee center center;
          width: 345px;
          height:105px;
        }
      }

      .service-info {
        margin-top: -5px;
        border: 1px solid #f1f1f2;
        padding: 20px 20px 35px 20px;

        @include mappy-bp(max-width small){
          margin-top: 0;
        }

        h1 {
          font-size: 22px;
          font-weight: 400;
          line-height: 24px;
          color: #231f20;

          @include mappy-bp(max-width small){
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 6px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #939598;

          @include mappy-bp(max-width small){
            font-size: 14px;
            line-height: 21px;
            color: #666666;
            margin-bottom: 28px;
          }
        }

        a {

          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #b9881f;
          
        }

        img {
          margin-left: 8px;
        }
      }

    }

    .quote {
      background-color: #e1a625;
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include mappy-bp(max-width small){
        margin-top: 40px;
      }

      .row1 {
        width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        height: 220px;
        background-image: url("/images/quote-bg-image.png");
        background-repeat:no-repeat;
        background-position: center center;
        background-color: #e1a625;
        justify-content: flex-end;
        align-items: center;
        z-index: 9999;

        @include mappy-bp(max-width huge){
          width: 100%;
        }

        @include mappy-bp (max-width small){
          height: 418px;
          background-color: none;
          background-image: url("/images/quote-bg-mobile.jpg");
          background-size:contain;
          padding-top: 40px;
          align-items: flex-start;
        }

        .quote-shape{
          margin-top: -87px;

          img {
            height: 12px;
            width: 15px;
            margin-right: 8px;
          }
          @include mappy-bp (max-width small){
            margin-top: 0px;
            margin-left: 30px;
            margin-right: none;
          }
        }


        .quote-content {
          flex-basis: 442px;
          margin-right: 167px;
          p {
            font-size: 17px;
            font-weight: 400;
            line-height: 24px;
            color: #000;
            
            @include mappy-bp (max-width small){
              font-size: 15.5px;
            line-height: 20px;
            }
          }

          @include mappy-bp (max-width small){
            flex-basis: none;
            margin-right: 0;
            padding: 0px 35px 0px 12px;
          }
        }

      }

      .row2 {
        display: flex;
        flex-direction: row;
        height: 430px;
        background-color: #f0efec;
        margin: 0 auto;
        justify-content: center;

        @include mappy-bp(max-width small){
          height: 300px;
        }

        .left-content {
          flex-basis: 698px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 145px;

          @include mappy-bp(max-width mediumish){
            padding-left: 15px;
            align-items: flex-start;
            padding-top: 60px;
          }

          .content {
            flex-basis: 480px;

            @include mappy-bp(small) {
              flex-basis: 357px;
            }

            h4 {
              font-size: 31px;
              font-weight: 400;
              color: #333;
              
              @include mappy-bp(max-width small) {
                font-size: 24px;
                line-height: 26px;
              }
            }

            p {
              font-size:15px;
              font-weight: 400;
              color: #666666;
              line-height: 24px;
              margin-bottom: 50px;

              @include mappy-bp(max-width small) {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 37px;
              }
            }

            .enquire-button {
              border:none;
              font-size: 18px;
              font-weight: 700;
              color: #fff;
              line-height: 20px;
              padding: 14px 60px;
              background-color: #da6129;
              border-bottom: 3px solid #b35022;
              height: 50px;
              width: 188px;
              margin: 0 5px 20px auto;
              border-radius: 1px;
              display: inline-block;
            }

            .quote-button {
              border: none;
              font-size: 16px;
              font-weight: 700;
              color: #fff;
              line-height: 11px;
              padding: 12px 3px;
              background-color: #da6129;
              border-bottom: 3px solid #b35022;
              height: 40px;
              width: 150px;
              margin: 0 5px 12px auto;
              border-radius: 1px;
              
            }

            .quote-button{
              display: none;
            }
            @include mappy-bp(max-width small){
              .enquire-button{
                display: none;
              }

              .quote-button{
                display:inline-block;
              }
            }
          }
        }

        .right-image {
          flex-basis: 742px;
          height: 430px;
          overflow-y: hidden;
          img {
            margin-top: -17px;
          }

          @include mappy-bp (max-width mediumish){
            display: none;
          }
        }
      }
    }
}
