/*********************************************
:: Service Page
********************************************/

.page-service {

  .title-module {
    width: 1150px;
    margin: 0 auto;
    display: block;

    h1 {
      font-size: 24px;
      line-height: 42px;
      padding: 48px 0 0 0px;
      font-weight: 600;

      @include mappy-bp(max-width mediumish){
        font-size: 20px;
        padding: 25px 0 40px 0;
        font-weight: 600;
        color: #231f20;
      }
    }

    h1:after {
      content: '';
      height: 5px;
      width: 40px;
      background: #e1a625;
      display: block;
      margin: 6px auto;

      @include mappy-bp(max-width mediumish){
        margin: -2px auto;
      }
    }

    @include mappy-bp(max-width 1150px){
      width: auto;
      padding-bottom: 30px;

    }
  }

  .title-bg {
    background-image: url("/images/desktop-title-banner.jpg");
    background-repeat: no-repeat;
    height: 150px;
    margin-top: 69px;
    width: 100%;

    @include mappy-bp(max-width mediumish){
      height: 100px;
      margin-top: 5px;

    }
  }

  .content-wrapper {
    width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: 27px 95px;
    justify-content: space-between;

    @include mappy-bp(max-width mediumish){
      width: auto;
      flex-direction: column;
      padding: 0px;

    }

    @include mappy-bp(max-width 1140px){
      width: auto;

    }

    .content-left {
      flex-basis: 535px;

      h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        color: #231f20;
      }

      .wysiwyg {
        ul li {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #696865;
          margin-left: 15px;

          @include mappy-bp(max-width mediumish){
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #666666;
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #696865;
  
          @include mappy-bp(max-width mediumish){
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #666666;
          }
        }
      }
      @include mappy-bp(max-width mediumish){
        width: auto;
        flex-direction: column;
        padding: 0px;
        flex-basis: 0px;

        .wysiwyg {
          padding: 0 15px 0 15px;
        }
      }

    }

    .image-right {
      flex-basis: 371px;
      padding-top: 5px;
      padding-left: 15px;

      img {
        border-top: 5px solid #e1a625;

      }
      @include mappy-bp(max-width medium){
        padding: 0 15px 0 15px;
      }

      @include mappy-bp(max-width small){
        padding:0px;
        width: 100%;
        text-align: center;
      }
    }
  }



}