/*********************************************
  :: Site Header
********************************************/
.site-header {
	position: relative;
	width: 100%;
	height: 109px;
	z-index: 99;
	margin: 0 auto;

	.nav-top-border {
		border-top: 4px solid $color-brand-1;

		@include mappy-bp(mediumish) {
			border-top: 10px solid $color-brand-1;

		}
	}
	.nav-wrapper{
		width: 1150px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include mappy-bp(max-width 1150px){
			width: auto;
		}
	}

	.nav-right {
		display: flex;
		justify-content: flex-end;
		margin-top: 50px;
		width: 100%;

	}

	@include mappy-bp(max-width mediumish){
		height: 60px;
	}

	@include  mappy-bp(max-width small){
		width: auto;
		height: 60px;
	}

	.logo {
		position: absolute;
		top: 50px;
		bottom: 5px;
		transition: none;


		.image2 {
			display: none;
		}

		@include mappy-bp(max-width 1150px){
			top: 35px;
			left: 3%;
		}

		@include mappy-bp(max-width mediumish){
			top: 20px;
			left: 3%;

			.image1 {
				display: none;
			}
			.image2 {
				display: block;
			}
		}
		@include mappy-bp(max-width small){
			top: 16px;
			left: 4%;
		}
	}

	.ongoing-text {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		color: #696865;

		&.selected {
			color: #696865;
		}
	}

	.contact-num {
		font-size: 24px;
		font-weight: 400;
		color: #Da6129;
		margin-left: 14px;
	}
	.ongoing-margin {
		margin-right: 20px;
	}

	nav {
		//position: absolute;
		//top: 59px;
		//right: 19%;

		text-align: right;

		@include mappy-bp(max-width mediumish){
			display: none;
		}

		@include mappy-bp(max-width 1450px) {
			right: 8.5%;
		}

		@include mappy-bp(max-width 1361px) {
			right: 5.5%;
		}

		ul &.bottom {
			margin-top: 9px;
		}


		li {
			display: inline-block;
			position: relative;
			margin-right: 27px;
			
		}

		a {
			display: inline-block;
			margin-left: 13.5px;
			color: #231f20;
			font-size: 18px;
			font-weight: 400;
			line-height: 20px;
			
			&:hover {
				opacity: 0.7;
			}

			&.selected {
				color: #b9881f;
				line-height: 20px;
				font-weight: 700;
				font-size: 18px;
				&:hover { opacity: 1; }
				&:active { opacity: 1; }
			}
		}

		.bottom {
			margin-top: 13px;
			margin-left: 67px;
		}
	}

	// parent link
	.parent{
		// padding-right: 15px;
		>a{
			position: relative;

			// arrow
			&:before{
				content: '';
				//background: url('/images/down-arrow.png') center right no-repeat;
				position: absolute;
				top: 50%;
				right: -5px;
				transform: translateY(-50%);
				width: 10px;
				height: 10px;
			}

			&.selected {
				color: #b9881f;
				line-height: 20px;
				font-weight: 700;
				font-size: 18px;
				&:hover { opacity: 1; }
				&:active { opacity: 1; }
			}

		}
	}

	// open-sub-menu
	.open-sub-menu{
		>a{

			&:before{
				transform: rotate(180deg) translateY(50%);
			}
		}
	}

	// sub menu
	.sub-menu{
		border-top: 3px solid #b35022;
		display: none;
		position: absolute;
		top: 30px;
		left: -10px;
		background-color: #f0efec;
		z-index: 20;
		width: 170px;
		padding: 10px 5px 0px 5px;
		text-align: left;
		margin: 0px;

		li{
			margin-bottom: 10px;
			&:before{
				display: none;
			}
			&:last-of-type{
				a{
					border-bottom: none;
				}
			}

		}

		a{
			color: #231f20;
			font-size: 18px;
			font-weight: 400;
			line-height: 1;
			padding: 5px 0px 5px 0px;
			width: 100%;
			display: inline-block;
			text-decoration: none;
			margin-left: 15px;
		}

	}

	.tel-mob {
		display: inline-block;
		position: absolute;
		top: 35px;
		right: 7.5%;

		font-size: 12px;
		line-height: 14px;
		letter-spacing: 2px;
		color: $color-brand-1;

		@include mappy-bp(mediumish){
			display: none;
		}
		
		@include mappy-bp(max-width mediumish){
			right: 19%;
			top: 20px;
		}
	}

	.hamburger {
        position: absolute;
        top: 35px; 
		right: 4%;

		width: 30px;
    	height: 20px;

		span {
			font-size: 17px;
			color: black;
		}

        &:focus {
            outline: none;
		}
		
		@include mappy-bp(max-width mediumish){
			top: 21px;
		}
    }

	.hamburger-image {
		max-width: 30px;
	}
}

/********************************************/