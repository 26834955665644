/*********************************************
  :: Site Footer
********************************************/
.site-footer {

    position: relative;
    width: 1440px ;
    min-height: 150px;
    background: white;
    margin: 0 auto;

    @include mappy-bp(mediumish){
        min-height: $footer-height-desktop;

    }

    @include mappy-bp(max-width huge){
        width: 100%;
    }


    .footer-legal {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        @include mappy-bp (mediumish) {
            top: 39%;
        }

        .content-max-width{
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            padding-left: 8%;
            padding-right: 8%;

            @include mappy-bp(max-width small){
                flex-wrap: wrap;
                padding-left: 20%;
                padding-right: 20%;
            }
        }

        p, a {
            display: inline-block;

            font-size: 14px;
            line-height: 11px;
            font-weight: 400;
            color: #939598;
            text-align: center;
        }

            //@include mappy-bp(huge){
            //    width:100%;
            //    margin-left: 145px;
            //    margin-right: 145px;
            //}
        }
 
        p.copyright {
            margin-bottom: 5px;

            @include mappy-bp(mediumish){
                float:left;
                margin-bottom: 0px;
            }

            @include mappy-bp(max-width small){
                margin-bottom: 15px;
            }

            a {
                font-size: 14px;
                line-height: 11px;
                font-weight: 400;
                color: #939598;
            }
        }

        a.attribution {
            font-size: 14px;
            line-height: 11px;
            font-weight: 400;
            color: #dcdcdc;

            @include mappy-bp(mediumish){
                float:right;
            }
        }
    }

