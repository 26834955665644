/*********************************************
:: Pump Page
********************************************/

.page-pump {

  .title-module {
    width: 1150px;
    margin: 0 auto;
    display: block;

    h1 {
      font-size: 24px;
      line-height: 42px;
      padding: 48px 0 0 31px;
      font-weight: 600;
      color: white;

      @include mappy-bp(max-width mediumish){
        padding: 25px 0 40px 31px;
      }

      @include mappy-bp(max-width mediumish){
        font-size: 20px;
        padding: 25px 0 40px 0;
        font-weight: 600;
        color: white;
        letter-spacing: -1.3px;
        margin-left: 29px;
      }
    }

    h1:after {
      content: '';
      height: 5px;
      width: 40px;
      background: #e1a625;
      display: block;
      margin: 4px 0;
      @include mappy-bp(max-width mediumish){
        margin-top: -2px;
      }
    }

    @include mappy-bp(max-width 1150px){
      width: auto;
    }
  }

  .pump-logo {
    margin-top: 10px;
  }

  .pump-btn {
    margin: 24px auto 48px auto;
  }

  .button {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    line-height: 20px;
    padding: 14px 18px;
    background-color: #e1a625;
    border-bottom: 3px solid #b9881f;
    height: 50px;
    // width: 188px;
    margin: 24px auto 48px auto;
  }

  .title-bg {
    background-image: url("/images/Group.png");
    background-repeat: no-repeat;
    height: 150px;
    margin-top: 69px;
    width: 100%;
    padding-left: 419px;

    @include mappy-bp(max-width mediumish){
      width: auto;
      height: 100px;
      padding-left: 0;
      margin-top: 5px;
    }

    @include mappy-bp(max-width small){
      width: auto;
      height: 100px;
      padding-left: 0px;
      margin-top: 5px;
    }
  }

  .title-holder{
    display:flex;
    flex-direction: row;
    align-items: center;

    .logo-m {
      display: none;
    }

    .logo{
      margin-top: 18px;
      margin-left: 3px;
    }

    @include mappy-bp(max-width mediumish){
      align-items: baseline;
      justify-content: center;
      height: 100px;

      .logo-m {
        display: block;
        align-self: center;
      }

      .logo {
        display: none;
      }
    }
  }

  .content-wrapper {
    width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include mappy-bp(max-width 1140px){
      width: auto;
    }

    @include mappy-bp(max-width mediumish){
      width: auto;
      flex-direction: column;
      align-items: center;
    }

  }

  .image-right {
    flex-basis: 375px;

    .col1 {
      display: flex;
      flex-direction: column;
      align-items: right;
      padding-left:88px;

      @include mappy-bp(max-width mediumish){
        padding-left:0;
        align-items: center;
      }

      .image-container{
        @include mappy-bp(max-width mediumish){
          width:355px;
          height: auto;
          padding-left: 0px;
        }
      }

      img.image-about {
        margin-top: -63px;
        border: 5px solid #fff;

        @include mappy-bp(max-width small){
          width: 355px;
          height:250px;

        }
      }

      img.image-about2 {
        display: none;
        margin-top: -63px;
        border: 5px solid #fff;

        @include mappy-bp(max-width mediumish){
          display:block;
          width: 100%;
          
        }
      }

      .rectangle {
        background:url(/images/rectangle.png) top right no-repeat;
        margin-right:5px;
        height:250px;
        margin-top:50px;

        @include mappy-bp(max-width mediumish){
          display: none;

        }
      }
    }
    @include mappy-bp(max-width mediumish){
      flex-basis: 0px;

    }
  }

  .content-left {
    flex-basis: 730px;
    float: left;

    .wysiwyg{
      padding: 0px 0px 0px 40px;
      width: 80%;

      @include mappy-bp(max-width small){
        margin-top: 25px;
        padding: 0px 0px 0px 0px;
        width: auto;
      }

      p, li {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: #696865;

        @include mappy-bp(max-width small){
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #666666;
          text-align: left;
        }
      }

      li {
        margin-left: 20px;

      }
    }

    @include mappy-bp(max-width small){
      padding: 0px 5px 0px 15px;
      width: 95%;
      float: none;
    }

    @include mappy-bp(max-width mediumish){
      width: 95%;
      flex-basis: 0px;
      float: none;
    }

  }

}